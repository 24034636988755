import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"our-services"} />
		<Helmet>
			<title>
				Наши услуги | Наши юридические услуги в агентстве Legal Vynolo
			</title>
			<meta name={"description"} content={"Ваши юридические потребности удовлетворяются с точностью и вниманием к нашему спектру услуг"} />
			<meta property={"og:title"} content={"Наши услуги | Наши юридические услуги в агентстве Legal Vynolo"} />
			<meta property={"og:description"} content={"Ваши юридические потребности удовлетворяются с точностью и вниманием к нашему спектру услуг"} />
			<meta property={"og:image"} content={"https://vynolo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vynolo.com/img/10830902.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vynolo.com/img/10830902.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vynolo.com/img/10830902.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vynolo.com/img/10830902.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vynolo.com/img/10830902.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vynolo.com/img/10830902.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Наши услуги
				</Text>
				<Text font="--lead" color="--darkL2" max-width="600px">
				Агентство Legal Vynolo занимается предоставлением комплексных юридических услуг, отвечающих вашим индивидуальным и деловым потребностям. От индивидуальных консультаций до стратегического юридического планирования - мы готовы провести вас через все этапы вашего юридического пути, проявляя компетентность и сочувствие.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://vynolo.com/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Юридические консультации и представительство
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Индивидуальная юридическая поддержка: Сочувственное и квалифицированное руководство при решении личных юридических вопросов, таких как семейное право, планирование недвижимости и травмы.
							<br />
							<br />
							Юридические решения для бизнеса: От корпоративной структуры и соблюдения требований законодательства до трудового права и защиты интеллектуальной собственности - мы предлагаем надежную поддержку юридических потребностей вашего бизнеса.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://vynolo.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Специализированные области права
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Право недвижимости: Если вы покупаете, продаете или управляете недвижимостью, наши экспертные советы обеспечат беспроблемные юридические сделки и защиту ваших интересов в сфере недвижимости.
							<br />
							<br />
							Защита по уголовным делам: Вы столкнулись с уголовными обвинениями? Наши стратегии защиты направлены на защиту ваших прав и обеспечение надежной защиты.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://vynolo.com/img/7.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Разрешение споров и посредничество
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Эффективные переговоры: Мы специализируемся на разрешении споров вне зала суда, экономя ваше время и стресс благодаря умелому ведению переговоров и посредничеству.
							<br />
							<br />
							Поддержка в судебных процессах: При необходимости судебного разбирательства наша опытная команда готова энергично представлять вас в суде, отстаивая ваши интересы.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Начните свой юридический путь с уверенностью
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
					Выберите агентство Legal Vynolo и сделайте первый шаг к уверенному решению ваших юридических вопросов. Свяжитесь с нами, чтобы узнать, как наши услуги могут быть адаптированы к вашим уникальным юридическим потребностям и целям.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});